import React from 'react';
import BookletReviewPopup from './BookletReviewPopup';
import BookletSelectedCompaniesPopup from './BookletSelectedCompaniesPopup';

function BookletInCompanyColumn(props) {
  const { coupons, booklet, adminEmails, zoneId, isCompanyInView, company, companyHeight, companyIndex, bookletIndex } = props;
  return (
    <td rowSpan={coupons.length} className={`pop-up-base`}>
      <BookletSelectedCompaniesPopup
        booklet={booklet}
        company={company}
        companyHeight={companyHeight}
        companyIndex={companyIndex}
        bookletIndex={bookletIndex}
      />
      <BookletReviewPopup
        coupons={coupons}
        booklet={booklet}
        adminEmails={adminEmails}
        zoneId={zoneId}
        isCompanyInView={isCompanyInView}
        companyHeight={companyHeight}
      />
    </td>
  )
}

export default BookletInCompanyColumn;
