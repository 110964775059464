import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { getCouponsQuery, addCouponMutation } from '../../queries/queries';
import { LoadingTable, sortList } from '../../common/index';
import CouponsForm from './CouponsForm';
import { FiEdit } from 'react-icons/fi';
import { IoDuplicateOutline } from 'react-icons/io5';
import BookletInCompanyColumn from './BookletInCompanyColumn';
import { useZoneContext } from '../../context/ZoneContext';
import { useMutation, useLazyQuery } from 'react-apollo';
import { useCompaniesTableContext } from '../../context/CompaniesTableContext';

function CouponsTable({ zoneId, adminEmails, company, checked, isCompanyInView, companyHeight, companyIndex }) {
  const { filteredBooklets, companiesFilter, setCompaniesFilter } = useZoneContext();
  const {
    markCompanyAsLoaded,
    currentBatchStart,
    batchSize,
    totalCompaniesCount,
  } = useCompaniesTableContext();

  const [addCoupon, { loading: addCouponLoading }] = useMutation(addCouponMutation);
  const [fetchCoupons, { data, loading, error }] = useLazyQuery(getCouponsQuery, {
    variables: { companyId: company._id },
    // fetchPolicy: 'network-only',
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      markCompanyAsLoaded(company._id, data.coupons);
    },
  });

  const [editing, setEditing] = useState('');
  const [sortBy, setSortBy] = useState('position');
  const [sortOrder, setSortOrder] = useState(1);

  const coupons = data?.coupons || [];
  const sort = { sortBy, sortOrder, setSortBy, setSortOrder };

  // Determines if this company should be loaded in the current batch:
  // - companyIndex >= currentBatchStart: Company is not in a previous batch
  // - companyIndex < currentBatchStart + batchSize: Company is within the current batch size
  const isInCurrentBatch = companyIndex >= currentBatchStart && companyIndex < currentBatchStart + batchSize;

  // Fetch coupons when it's this company's turn OR when it comes into view
  const shouldFetchCoupons = totalCompaniesCount && (isCompanyInView || isInCurrentBatch);
  // const shouldFetchCoupons = totalCompaniesCount && isInCurrentBatch;

  useEffect(() => {
    if (shouldFetchCoupons) {
      fetchCoupons();
    }
  }, [fetchCoupons, shouldFetchCoupons]);

  // Function to determine if a company should be hidden
  const shouldHideCompany = (companyId, selectedBooklet, checkedCoupons, coupons) => {
    if (!selectedBooklet || !checkedCoupons || !coupons) return false;

    const relevantCheckedCoupons = checkedCoupons.filter(checkedItem => selectedBooklet.couponIds.includes(checkedItem.split('-')[1]));
    const couponIdsInChecked = relevantCheckedCoupons.map(checkedItem => checkedItem.split('-')[1]);
    return !coupons.some(coupon => coupon.companyId === companyId && couponIdsInChecked.includes(coupon._id));
  };

  // Get the relevant checked coupons for the selected booklet
  const relevantCheckedCoupons = useMemo(() => {
    return checked.filter(checkedItem => {
      const booklet = filteredBooklets?.find(b => b._id === companiesFilter.selectedBookletId);
      return booklet && booklet.couponIds.includes(checkedItem.split('-')[1]);
    });
  }, [checked, companiesFilter, filteredBooklets]);

  // useEffect hook to update hiddenCompanies
  useEffect(() => {
    if (!company._id || !companiesFilter) return;

    const selectedBooklet = filteredBooklets?.find(b => b._id === companiesFilter.selectedBookletId);

    const hideCompany = shouldHideCompany(company._id, selectedBooklet, relevantCheckedCoupons, coupons);

    setCompaniesFilter(prevState => {
      const hiddenCompaniesSet = new Set(prevState.hiddenCompanies);
      const needsUpdate = hideCompany ? hiddenCompaniesSet.add(company._id).size !== prevState.hiddenCompanies.length
        : hiddenCompaniesSet.delete(company._id);

      return needsUpdate ? { ...prevState, hiddenCompanies: Array.from(hiddenCompaniesSet) } : prevState;
    });
  }, [companiesFilter, relevantCheckedCoupons, coupons, company._id, setCompaniesFilter]);

  const handleDuplicate = (e, coupon) => {
    e.stopPropagation();
    if (addCouponLoading) return;

    addCoupon({
      variables: {
        companyId: company._id,
        imageURL: coupon.imageURL,
        discount: coupon.discount,
        smallTitle: coupon.smallTitle,
        productLink: coupon.productLink,
        terms: coupon.terms,
        description: coupon.description,
        code: coupon.code,
      },
      refetchQueries: [{ query: getCouponsQuery, variables: { companyId: company._id } }]
    }).then((res) => {
      console.log('duplicated', res);
    }).catch((err) => {
      console.log('error', err);
    });
  }

  return (
    <>
      {editing === 'new' && <CouponsForm key="new" content={{ companyId: company._id }} onComplete={() => setEditing('')} />}
      <tr id={`locator-${company._id}`}></tr>
      {
        // Table row for coupon
        !data?.coupons ? <LoadingTable loading={loading} error={error} /> : (
          sortList(coupons, sort).map((couponObj, index) => (
            editing === couponObj._id
              ? <CouponsForm key={couponObj._id} content={couponObj} onComplete={() => setEditing('')} />
              : (
                <tr
                  key={couponObj._id}
                  className='coupon'
                >
                  <td></td>
                  <td 
                    colSpan={3} 
                    className={`coupon-title ${companiesFilter.hiddenCompanies.includes(company._id) ? 'faded' : ''}`}
                    onClick={() => setEditing(couponObj._id)}
                  >
                    <span>
                      {couponObj.discount + ' ' + couponObj.smallTitle}
                      <span>
                        <button onClick={(e) => handleDuplicate(e, couponObj)}><IoDuplicateOutline size={20} /></button>
                        <button onClick={() => setEditing(couponObj._id)}><FiEdit size={20} /></button>
                      </span>
                    </span>
                  </td>
                  {index === 0 && (
                    filteredBooklets?.map((bookletItem, index) => (
                      <BookletInCompanyColumn
                        key={bookletItem._id}
                        coupons={sortList(coupons, sort)}
                        booklet={bookletItem}
                        adminEmails={adminEmails}
                        zoneId={zoneId}
                        company={company}
                        isCompanyInView={isCompanyInView}
                        companyHeight={companyHeight}
                        companyIndex={companyIndex}
                        bookletIndex={index}
                      />
                    ))
                  )}
                </tr>
              )
          ))
        )
      }
      <tr className='add-coupons'>
        <td></td>
        <td className={companiesFilter.hiddenCompanies.includes(company._id) ? 'faded' : ''}>
          <button className='add-coupon' onClick={() => setEditing('new')}>Lägg till kupong</button>
        </td>
        <td colSpan={2}></td>
      </tr>
    </>
  );
}

CouponsTable.propTypes = {
  zoneId: PropTypes.string.isRequired,
  adminEmails: PropTypes.array.isRequired,
  company: PropTypes.object.isRequired,
  checked: PropTypes.array.isRequired,
  isCompanyInView: PropTypes.bool.isRequired,
};

export default CouponsTable;
