import gql from 'graphql-tag';

// QUERIES

const getBookletsQuery = gql`
  {
    booklets: booklets {
      _id
      title
      adminEmails
      buyableFrom
      buyableTo
      validFrom
      validTo
      price
      zoneId
      soldBooklets
      usedOrders
      logoURL
      colorLeft
      colorRight
      colorFont
      couponIds
      imageOfBookletInAppURL
      shopURL
      purchasableThroughAssociations
    }
  }
`;

const getBookletsInZoneQuery = gql`
  query ($zoneId: ObjectId!) {
    booklets: bookletsInZone(zoneId: $zoneId) {
      _id
      title
      adminEmails
      buyableFrom
      buyableTo
      validFrom
      validTo
      price
      soldBooklets
      usedOrders
      logoURL
      colorLeft
      colorRight
      colorFont
      couponIds
      imageOfBookletInAppURL
      shopURL
      purchasableThroughAssociations
      categorySettings {
        categoryId
        color
        order
      }
      textInsidePrintedBooklet
    }
  }
`;

const getAdminableBookletsQuery = gql`
  query ($zoneId: ObjectId!) {
    booklets: myAdminableBooklets(zoneId: $zoneId) {
      _id
      title
      couponIds
      buyableFrom
      buyableTo
      validFrom
      validTo
      price
      zoneId
    }
  }
`;

const getBuyableBookletsQuery = gql`
  {
    booklets: buyableBooklets{
      _id
      title
      validFrom
      validTo
      price
      logoURL
      colorLeft
      colorRight
      colorFont
      zoneId
      purchasableThroughAssociations
    }
  }
`;

const getBookletQuery = gql`
  query($_id: ObjectId!) {
    booklet: booklet(_id: $_id) {
      _id
      title
      validFrom
      validTo
      colorLeft
      colorRight
      colorFont
      logoURL
      userOwnsBooklet
      price
      imageOfBookletInAppURL
      shopURL
      categorySettings {
        categoryId
        color
        order
      }
      textInsidePrintedBooklet
      calculatedCompanies {
        _id
        name
        logoURL
        websiteURL
        typeId
        companyCategoryId
        phone
        visitAddress {
          streetAddress
          city
        }
        calculatedCoupons {
          _id
          discount 
          description
          smallTitle
          productLink
          availableByUser
          terms
          imageURL
          companyId
          code
        }
      }
    }
  }
`;

const getBookletNameQuery = gql`
  query BookletName($_id: ObjectId!) {
    booklet: booklet(_id: $_id) {
      title
    }
  }
`;

const categoriesInBookletQuery = gql`query CategoriesInBooklet($bookletId: ObjectId!) {
  categoriesInBooklet(bookletId: $bookletId) {
    name,
    _id,
    iconPath
  }
}`;

const companyFromBookletQuery = gql`query CompanyFromBooklet($bookletId: ObjectId!, $companyId: ObjectId!) {
  companyFromBooklet(bookletId: $bookletId, companyId: $companyId
  ) {
    _id
    name
    logoURL
    websiteURL
    typeId
    companyCategoryId
    phone
    visitAddress {
      streetAddress
      city
    }
    calculatedCoupons {
      _id
      discount 
      description
      smallTitle
      productLink
      availableByUser
      terms
      imageURL
    }
  }
}`;

const getBookletByReviewTokenQuery = gql`query($token: String!) {
  bookletByReviewToken(token: $token
  ) {
    validTo
    colorLeft
    colorRight
    logoURL
    title
    colorFont
    calculatedCompanies {
      _id
      name
      logoURL
      websiteURL
      typeId
      companyCategoryId
      phone
      visitAddress {
        streetAddress
        city
      }
      calculatedCoupons {
        _id
        discount 
        description
        smallTitle
        productLink
        availableByUser
        terms
        imageURL
        companyId
        code
      }
    }
  }
}`;



// MUTATIONS

const addBookletMutation = gql`
  mutation(
    $title: String!
    $adminEmails: [String!]
    $buyableFrom: DateTime!
    $buyableTo: DateTime!
    $validFrom: DateTime!
    $validTo: DateTime!
    $price: Float
    $logoURL: String
    $colorLeft: String
    $colorRight: String
    $colorFont: String
    $imageOfBookletInAppURL: String 
    $purchasableThroughAssociations: [String!]
    $zoneId: ObjectId!
    $textInsidePrintedBooklet: String
    ) {
    createBooklet(
      title: $title
      adminEmails: $adminEmails,
      buyableFrom: $buyableFrom,
      buyableTo: $buyableTo,
      validFrom: $validFrom,
      validTo: $validTo,
      price: $price,
      logoURL: $logoURL,
      colorLeft: $colorLeft,
      colorRight: $colorRight,
      colorFont: $colorFont
      imageOfBookletInAppURL: $imageOfBookletInAppURL
      purchasableThroughAssociations: $purchasableThroughAssociations
      zoneId: $zoneId
      textInsidePrintedBooklet: $textInsidePrintedBooklet
      ) {
        title
    }
  }
`;

const updateBookletMutation = gql`
  mutation(
    $_id: ObjectId!
    $title: String!
    $adminEmails: [String!]
    $buyableFrom: DateTime!
    $buyableTo: DateTime!
    $validFrom: DateTime!
    $validTo: DateTime!
    $price: Float
    $logoURL: String
    $colorLeft: String
    $colorRight: String
    $colorFont: String
    $couponIds: [String!]
    $imageOfBookletInAppURL: String
    $purchasableThroughAssociations: [String!]
    $categorySettings: [BookletCategorySettingsInput]
    $textInsidePrintedBooklet: String
    ) {
    updateBooklet(
      _id: $_id,
      title: $title
      adminEmails: $adminEmails,
      buyableFrom: $buyableFrom,
      buyableTo: $buyableTo,
      validFrom: $validFrom,
      validTo: $validTo,
      price: $price,
      logoURL: $logoURL,
      colorLeft: $colorLeft,
      colorRight: $colorRight,
      colorFont: $colorFont,
      couponIds: $couponIds
      imageOfBookletInAppURL: $imageOfBookletInAppURL
      purchasableThroughAssociations: $purchasableThroughAssociations
      categorySettings: $categorySettings
      textInsidePrintedBooklet: $textInsidePrintedBooklet
      ) {
        title
    }
  }
`;

const updateCouponsInBookletMutation = gql`
  mutation(
    $_id: ObjectId!
    $title: String!
    $couponIds: [String!]
    ) {
    updateBooklet(
      _id: $_id,
      title: $title
      couponIds: $couponIds
      ) {
        title
    }
  }
`;

const generateCompletedOrderBatchPDF = gql`
  mutation(
    $batchLabel: String!,
    $bookletId: ObjectId!,
    $mode: String!
    ) {
    generateCompletedOrderBatchPDF(
      batchLabel: $batchLabel,
      bookletId: $bookletId,
      mode: $mode
    )
  }
`;

const generateCompletedOrderPDF = gql`
  mutation(
    $bookletId: ObjectId!,
    $orderId: ObjectId!,
    $encryptedOrderCode: String!
  ) {
    generateCompletedOrderPDF(
      bookletId: $bookletId,
      orderId: $orderId,
      encryptedOrderCode: $encryptedOrderCode
    )
  }
`;

const sendOrderBatchPdfLink = gql`
  mutation(
    $email: GraphQLEmail!,
    $bookletId: ObjectId!,
    $batchLabel: String!,
    $portalUrlForEmails: String!
  ) {
    sendOrderBatchPdfLink(
      email: $email,
      bookletId: $bookletId,
      batchLabel: $batchLabel,
      portalUrlForEmails: $portalUrlForEmails
    )
  }
`;

const sendOrderPdfLink = gql`
  mutation(
    $emails: [GraphQLEmail!]!,
    $bookletId: ObjectId!,
    $orderCode: String!,
    $orderId: ObjectId!,
    $portalUrlForEmails: String!
    $title: String!
    $message: String!
  ) {
    sendOrderPdfLink(
      emails: $emails,
      bookletId: $bookletId,
      orderCode: $orderCode,
      orderId: $orderId,
      portalUrlForEmails: $portalUrlForEmails,
      title: $title,
      message: $message
    )
  }
`;

export {
  getBookletsQuery,
  getBookletsInZoneQuery,
  getBuyableBookletsQuery,
  getBookletQuery,
  getBookletNameQuery,
  getAdminableBookletsQuery,
  getBookletByReviewTokenQuery,
  categoriesInBookletQuery,
  companyFromBookletQuery,
  addBookletMutation,
  updateBookletMutation,
  updateCouponsInBookletMutation,
  generateCompletedOrderBatchPDF,
  generateCompletedOrderPDF,
  sendOrderBatchPdfLink,
  sendOrderPdfLink
};
