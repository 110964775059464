import React, { useState, useEffect } from 'react';
import { graphql } from 'react-apollo';
import { useQuery } from '@apollo/react-hooks';
import compose from 'lodash.flowright';
import PropTypes from 'prop-types';
import { getCompaniesQuery, getCompanyCategoriesQuery, getCompanyTypesQuery, getAdminableBookletsQuery, getBookletsQuery } from '../../queries/queries';
import { LoadingTable, EditButton, sortList, SortButton, TableSection } from '../../common/index';
import CompanyForm from './CompaniesForm';
import Sort from '../../img/sort.svg';
import CompaniesTableBooklet from './CompaniesTableBooklet';
import CompanyTableItem from './CompanyTableItem';
import { useZoneContext } from '../../context/ZoneContext';
import { CompaniesTableProvider, useCompaniesTableContext } from '../../context/CompaniesTableContext';
import Select from 'react-select';

function CompaniesTableView(props) {
  const { categoriesQuery, typesQuery, zoneId } = props;
  const { data: companiesData, loading: companiesLoading, error: companiesError } = useQuery(getCompaniesQuery, { variables: { zoneId }, fetchPolicy: 'network-only' });
  const { data: bookletsData } = useQuery(getBookletsQuery);
  const { data: adminableBookletsData } = useQuery(getAdminableBookletsQuery, { variables: { zoneId } });

  const { filteredBooklets, setCompanies, setAdminableBooklets, setFilteredBooklets, showAllBooklets, zone } = useZoneContext();
  const { setTotalCompaniesCount } = useCompaniesTableContext();

  const [selectedOption, setSelectedOption] = useState(null);
  const [editing, setEditing] = useState('');
  const [sortBy, setSortBy] = useState('name');
  const [sortOrder, setSortOrder] = useState(1);
  const [checked, setChecked] = useState([]);
  const sort = { sortBy, sortOrder, setSortBy, setSortOrder };

  useEffect(() => {
    if (companiesData?.companies) {
      setCompanies(companiesData.companies);
      setTotalCompaniesCount(companiesData.companies.length);
    }
  }, [companiesData, setCompanies, setTotalCompaniesCount]);

  useEffect(() => {
    if (adminableBookletsData?.booklets) {
      const today = new Date();
      const booklets = showAllBooklets
        ? adminableBookletsData.booklets
        : adminableBookletsData.booklets.filter(booklet =>
          booklet.zoneId === zone?._id && new Date(booklet.validFrom) > today
        );

      setFilteredBooklets(booklets);
      setAdminableBooklets(adminableBookletsData.booklets);
    }
  }, [adminableBookletsData, showAllBooklets, zone]);

  function populateEditableCheckboxes() {
    setChecked([]);
    if (adminableBookletsData?.booklets) {
      adminableBookletsData.booklets.forEach((bookletObj) => {
        if (bookletObj.couponIds) {
          const couponIds = bookletObj.couponIds;
          const bookletId = bookletObj._id;
          if (couponIds.length > 0) {
            couponIds.forEach((coupon) => {
              let id = `${bookletId}-${coupon}`;
              setChecked((prevChecked) => [...prevChecked, id])
            })
          }
        }
      })
    }
  }

  useEffect(() => {
    populateEditableCheckboxes();
  }, [adminableBookletsData])

  const handleSelectChange = (option) => {
    if (option) {
      const selectedBooklet = bookletsData.booklets.find(booklet => booklet._id === option.value);
      setFilteredBooklets(prev => [...prev, selectedBooklet]);
      setSelectedOption(null);
    }
  };

  const bookletOptions = bookletsData?.booklets
    .filter(booklet =>
      // Filter out booklets that exist in filteredBooklets
      !filteredBooklets.some(filtered => filtered._id === booklet._id)
    )
    .map((booklet) => ({
      value: booklet._id,
      label: booklet.title,
    }))
    .sort((a, b) => a.label.localeCompare(b.label)) || [];

  return (
    <TableSection>
      <div className="table-header">
        <h2>Företag</h2>
        <EditButton size="sm add" setEditing={() => setEditing('new')}>Lägg till</EditButton>
      </div>
      <table className='company-table'>
        <thead>
          <tr className='company'>
            <td className="centered-logo">Bild</td>
            <td className='company-name'><SortButton name="name" sort={sort}>Företag<img className="sortBtn" src={Sort} alt="" /></SortButton></td>
            <td><SortButton name="categoryId" sort={sort}>Kategori<img className="sortBtn" src={Sort} alt="" /></SortButton></td>
            <td className='show-all-booklets-container'>
              <Select
                value={selectedOption}
                options={bookletOptions}
                onChange={handleSelectChange}
                placeholder="Visa häfte"
                isSearchable
                styles={{
                  control: (base) => ({
                    ...base,
                    width: 250,
                  }),
                  menuList: (base) => ({
                    ...base,
                    overflowX: 'hidden',
                  }),
                }}
              />
            </td>
            {
              filteredBooklets.map((bookletobj) => (
                <td key={bookletobj._id} className="booklet-info booklet-column">
                  <CompaniesTableBooklet booklet={bookletobj} />
                </td>
              ))
            }
          </tr>
          <tr className="button-row">
            <td></td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {
            editing === 'new'
            && <CompanyForm key="new" categories={categoriesQuery} types={typesQuery} content={{ zoneId }} onComplete={() => setEditing('')} />
          }
          {
            !companiesData?.companies ? <LoadingTable loading={companiesLoading} error={companiesError} /> : (
              sortList(companiesData.companies, sort).map((company, index) => (
                editing === company._id
                  ? <CompanyForm key={company._id} categories={categoriesQuery} types={typesQuery} content={company} onComplete={() => setEditing('')} />
                  : (
                    <CompanyTableItem
                      key={company._id}
                      company={company}
                      categoriesQuery={categoriesQuery}
                      setEditing={setEditing}
                      companyId={company._id}
                      adminEmails={company.adminEmails}
                      checked={checked}
                      zoneId={zoneId}
                      companyIndex={index}
                    />
                  )
              ))
            )
          }
        </tbody>
      </table>
    </TableSection>
  );
}

function CompaniesTable(props) {
  return (
    <CompaniesTableProvider>
      <CompaniesTableView {...props} />
    </CompaniesTableProvider>
  );
}

CompaniesTable.propTypes = {
  categoriesQuery: PropTypes.object.isRequired,
  typesQuery: PropTypes.object.isRequired,
  zoneId: PropTypes.string.isRequired,
};

export default compose(
  graphql(getCompanyCategoriesQuery, { name: 'categoriesQuery' }),
  graphql(getCompanyTypesQuery, { name: 'typesQuery' })
)(CompaniesTable);
