import React from 'react'
import OrderBatchDownloadPDF from './OrderBatchDownloadPDF';

const OrderBatch = (props) => {
  const { bookletId, batchLabel } = props.match.params;

  return (
    <div className="h-screen flex flex-col items-center justify-center">
      <h2 className='mb'>Värdebevis</h2>
      <p className='mb-lg text-center'>Här kommer era värdebevis för ett häfte fullproppat med fantastiska erbjudanden.</p>
      <OrderBatchDownloadPDF isEmailView bookletId={bookletId} batchLabel={batchLabel} />
    </div>
  )
}

export default OrderBatch
