import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-apollo';
import { completedOrdersQuery } from '../../queries/queries';
import Modal from '../../common/Modal';
import FieldWrapper from '../../common/FieldWrapper';
import BookletOrderBatchItem from './BookletOrderBatchItem';
import BookletDownloadPDFModal from './BookletDownloadPDFModal';


const BookletsPreviousOrderBatches = ({ booklet }) => {
  const { data, loading } = useQuery(completedOrdersQuery, { variables: { bookletId: booklet && booklet._id } });

  const [ordersWithBatchLabel, setOrdersWithBatchLabel] = useState([]);
  const [ordersWithoutBatchLabel, setOrdersWithoutBatchLabel] = useState(null);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Set order batches
  useEffect(() => {
    if (data?.completedOrdersByBooklet) {
      const ordersWithBatchLabel = data.completedOrdersByBooklet.filter(order => order.batchLabel);
      const ordersWithoutBatchLabel = data.completedOrdersByBooklet.filter(order => !order.batchLabel);

      // Set orders without batch label
      if (ordersWithoutBatchLabel.length > 0) {
        const ordersWithoutBatch = {
          orders: ordersWithoutBatchLabel
        };
        setOrdersWithoutBatchLabel(ordersWithoutBatch);
      } else {
        setOrdersWithoutBatchLabel(null);
      }

      // Set orders with batch label
      const batchMap = new Map();

      ordersWithBatchLabel.forEach(order => {
        if (!batchMap.has(order.batchLabel)) {
          batchMap.set(order.batchLabel, []);
        }
        batchMap.get(order.batchLabel).push(order);
      });

      // Sort the batches by completed date
      const sortedBatches = Array.from(batchMap.entries()).sort((a, b) => {
        const dateA = new Date(a[1][0].completedDate);
        const dateB = new Date(b[1][0].completedDate);
        return dateB - dateA;
      });

      // Create array of order batches
      const orderBatchesArray = sortedBatches.map(([batchLabel, orders]) => {
        const completedDate = new Date(orders[0].completedDate).toLocaleDateString();
        return {
          batchLabel,
          completedDate,
          orders
        };
      });

      setOrdersWithBatchLabel(orderBatchesArray);
    }
  }, [data]);

  // Function to handle opening the modal
  const openModal = (batch) => {
    setSelectedBatch(batch);
    setIsModalOpen(true);
  };

  // Function to handle closing the modal
  const closeModal = () => {
    setSelectedBatch(null);
    setIsModalOpen(false);
  };

  return (
    <>
      <FieldWrapper x2>
        <div className="flex flex-col text-left gap-1" style={{ maxWidth: '100%' }}>

          {/* Display loading */}
          {loading && <p>Laddar...</p>}

          {/* Display orders without batch label */}
          {ordersWithoutBatchLabel && <BookletOrderBatchItem
            key={ordersWithoutBatchLabel.batchLabel}
            batch={ordersWithoutBatchLabel}
            batchLabel='Alla ordrar'
            openModal={openModal}
          />}

          {/* Display orders with batch label */}
          {ordersWithBatchLabel.length > 0 && (
            <div className='flex flex-col'>
              <div className="flex items-center gap-1">
                <small>Genererade värdebevis</small>
              </div>
              <div className="previous-order-batches">
                {ordersWithBatchLabel?.map(batch => (
                  <BookletOrderBatchItem
                    key={batch.batchLabel}
                    batch={batch}
                    batchLabel={batch.batchLabel}
                    openModal={openModal}
                  />
                ))}
              </div>
            </div>
          )}

          {/* Display message if no orders */}
          {!loading && !ordersWithoutBatchLabel && ordersWithBatchLabel.length === 0 && <p>Inga tidigare ordrar.</p>}

        </div>
      </FieldWrapper>

      {isModalOpen && (
        <Modal isOpen={isModalOpen} closeModal={closeModal} className='order-list-modal'>
          <BookletDownloadPDFModal bookletId={booklet._id} selectedBatch={selectedBatch} />
        </Modal>
      )}
    </>
  );
};

export default BookletsPreviousOrderBatches;
