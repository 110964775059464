import React from 'react';

const PaperBookletsCategories = ({ categories, categorySettings, setCategorySettings }) => {
  const categoriesWithSettingsIncluded = categories?.map(category => {
    const categorySetting = categorySettings?.find(cs => cs.categoryId === category._id) || { color: '#FFFFFF' };
    return {
      ...category,
      color: categorySetting.color
    };
  });

  const handleColorChange = (categoryId, color) => {
    const newCategorySettings = categorySettings.map(cs =>
      cs.categoryId === categoryId ? { ...cs, color } : cs
    );

    // If the category is not in categorySettings, add it
    if (!newCategorySettings.find(cs => cs.categoryId === categoryId)) {
      newCategorySettings.push({ categoryId, color });
    }

    setCategorySettings(newCategorySettings);
  };

  return (
    <div className="paper-booklets-categories flex">
      {categoriesWithSettingsIncluded?.map((category, index) => (
        <div key={index} className="category-color-select flex items-center">
          <div className="selected-color">
            <strong>{category.name}</strong>
            <div className="flex items-center relative" style={{ backgroundColor: category.color }}>
              <input
                type="color"
                value={category.color}
                onChange={(e) => handleColorChange(category._id, e.target.value)}
                className="color-input"
              />
              <strong className="color-name">
                {categorySettings?.find(cs => cs.categoryId === category._id)?.color || 'Välj färg'}
              </strong>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PaperBookletsCategories;
