import React, { useEffect, useRef } from 'react'
import { useQuery } from 'react-apollo'
import { getBookletQuery, categoriesInBookletQuery, getMyUserQuery } from '../../queries/queries'
import { useNavigationContext } from '../../context/NavigationContext'
import { Link, useHistory, useLocation } from 'react-router-dom';
import { IoChevronBackSharp } from 'react-icons/io5';
import { REACT_APP_IMAGE_BASE_URL } from '../../env';
import ReactToPrint from 'react-to-print'
import { ImFilePdf } from 'react-icons/im';
import PageWrapper from './PageWrapper'
import TableOfContent from './TableOfContent';
import CompanyCoupons from './CompanyCoupons';
import { PappersHafteProvider, usePappersHafteContext } from '../../context/PappersHafteContext';
import { IoCheckmarkSharp } from 'react-icons/io5';
import QRCode from 'qrcode.react';
import { FaCircle } from 'react-icons/fa';


function PappersHafteView({ match }) {
  const location = useLocation();
  const { setBooklet, setBookletCategories } = usePappersHafteContext();
  const { data: bookletData } = useQuery(getBookletQuery, { variables: { _id: match.params.booklet }, fetchPolicy: 'network-only' });
  const { data: bookletCategories } = useQuery(categoriesInBookletQuery, { variables: { bookletId: match.params.booklet }, fetchPolicy: 'network-only' });
  const { data: userData, loading: userLoading } = useQuery(getMyUserQuery);

  const validFrom = new Date(bookletData?.booklet?.validFrom).toLocaleDateString('sv-SE').replace(/-/g, '').slice(2);
  const validTo = new Date(bookletData?.booklet?.validTo).toLocaleDateString('sv-SE').replace(/-/g, '').slice(2);
  const validPeriod = `${validFrom} – ${validTo}`;
  const isPreview = location.pathname.substring(1, 7) === 'cities';

  const formattedShopURL = bookletData?.booklet?.shopURL ? bookletData?.booklet?.shopURL.replace(/^https?:\/\/(www\.)?/, '').replace(/\/$/, '') : '';

  const bookletsRef = useRef(null);
  const history = useHistory();

  const { setNavigationActivated } = useNavigationContext();
  useEffect(() => {
    if (isPreview) {
      setNavigationActivated(true);
    } else {
      setNavigationActivated(false);
    }
  }, []);

  useEffect(() => {
    setBooklet(bookletData?.booklet);
    setBookletCategories(bookletCategories?.categoriesInBooklet);
  }, [bookletData, bookletCategories]);

  if (!userLoading && !userData?.user.superAdmin) {
    alert('Du har inte behörighet att se denna sida');
    history.push('/');
  }


  const TextInsidePrintedBooklet = ({ text, color }) => {
    const lines = text.split('\n\n');
    return (
      <div className='flex flex-col' style={{ color }}>
        {lines.map((paragraph, index) => {
          if (index === 0) {
            return <h1 key={index}>{paragraph}</h1>;
          }
          return <p key={index}>{paragraph.split('\n').map((line, index) => <span key={index}>{line}<br /></span>)}</p>;
        })}
      </div>
    );
  }

  return (
    <div id="pappershafte" className="booklets-view">
      <div ref={bookletsRef} className='booklets'>
        <div className='booklets-header ph-header'>
          <Link className='change-booklet' to='/pappershaften'><IoChevronBackSharp /> Byt häfte</Link>
          {bookletData &&
            <ReactToPrint
              trigger={() => <button className="pdf">
                <ImFilePdf />
                Skriv ut
              </button>}
              bodyClass="booklet-pdf"
              content={() => bookletsRef.current}
            />
          }
        </div>
        <PageWrapper className='start-page' bgColor={bookletData?.booklet?.colorLeft}>
          <div>
            <p className='ph-price' style={{ color: bookletData?.booklet.colorFont || '#fff' }}>Pris {bookletData?.booklet?.price}:-</p>
            <p className='ph-valid-to' style={{ color: bookletData?.booklet.colorFont || '#fff' }}>Giltighetsperiod: {validPeriod}</p>
          </div>
          <div>
            <img src={REACT_APP_IMAGE_BASE_URL + bookletData?.booklet.logoURL} alt={bookletData?.booklet.title} />
          </div>
        </PageWrapper>
        <PageWrapper className='start-text' bgColor={bookletData?.booklet?.colorLeft}>
          <TextInsidePrintedBooklet
            text={bookletData?.booklet?.textInsidePrintedBooklet || ''}
            color={bookletData?.booklet.colorFont}
          />
          {bookletData?.booklet.imageOfBookletInAppURL &&
            <img src={REACT_APP_IMAGE_BASE_URL + bookletData?.booklet.imageOfBookletInAppURL} alt={bookletData?.booklet.title} />
          }
        </PageWrapper>
        <TableOfContent />
        <CompanyCoupons />
        <PageWrapper className='end-info-page'>
          <div className='flex flex-col'>
            <h2 style={{ color: bookletData?.booklet.colorLeft || '#222' }}>Bra att veta</h2>
            <ul>
              <li>Erbjudandet gäller på ordinarie priser och kan inte omförhandlas,
                bytas mot kontanter eller kombineras med andra erbjudanden.</li>
              <li>Kupongen ska lämnas innan betalning eller om beställning redan vid beställningstillfället.</li>
              <li>Kupongen gäller vid ett köptillfälle.</li>
              <li>Giltighetstid – se rabattkupong.</li>
              <li>Rabattkupongen returneras inte vid retur av vara.</li>
              <li>Erbjudandet gäller inte vid köp av presentkort.</li>
              <li>Mitt häfte AB reserverar sig för eventuella tryckfel samt upphörande av erbjudande om verksamheter upphör eller ändrar karaktär.</li>
              <li>Framtida ombud eller återförsäljare av häften? Kontakta oss: info@mitthafte.se</li>
            </ul>
          </div>
        </PageWrapper>
        <PageWrapper className='end-page flex flex-col justify-center'>
          <h2>Du har fler kuponger i appen</h2>
          <div className="checkmark-row flex">
            <div className="flex items-center">
              <IoCheckmarkSharp color={bookletData?.booklet.colorLeft} />
              <p>Alltid i fickan</p>
            </div>
            <div className="flex items-center">
              <IoCheckmarkSharp color={bookletData?.booklet.colorLeft} />
              <p>Dela med familjen</p>
            </div>
            <div className="flex items-center">
              <IoCheckmarkSharp color={bookletData?.booklet.colorLeft} />
              <p>Spara tusenlappar</p>
            </div>
          </div>
          <div className="steps-row flex">
            <div className="flex flex-col">
              <div className='step-group'>
                <div className="step flex">
                  <div>
                    <FaCircle style={{ height: '100%', width: '100%', color: bookletData?.booklet.colorLeft, position: 'absolute' }} />
                    <span style={{ color: bookletData?.booklet.colorFont }}>1</span>
                  </div>
                  <p>Ladda ned appen</p>
                </div>
                <p>Skanna QR-koden eller sök på Så Enkelt i App Store/Google Play</p>
              </div>
              <div className='step-group'>
                <div className="step flex">
                  <div>
                    <FaCircle style={{ height: '100%', width: '100%', color: bookletData?.booklet.colorLeft, position: 'absolute' }} />
                    <span style={{ color: bookletData?.booklet.colorFont }}>2</span>
                  </div>
                  <p>Ange koden i appen</p>
                </div>
                <p>Koden är giltig t.o.m. {new Date(bookletData?.booklet?.validTo).toLocaleDateString('sv-SE')}</p>
                <div className='code'>76MH00RW5</div>
              </div>
              {formattedShopURL && <p className='shop-url'>Köp fler häften på {formattedShopURL}</p>}
            </div>
            <div className="qr-code">
              <QRCode
                value='https://saenkelt.se/download/'
                size={92}
              />
            </div>
          </div>
        </PageWrapper>
      </div>
    </div>
  )
}

export default function PappersHafteViewWrapper(props) {
  return (
    <PappersHafteProvider>
      <PappersHafteView {...props} />
    </PappersHafteProvider>
  );
}
