
const local = {
  REACT_APP_BRAND: 'SAENKELT',
  REACT_APP_API_HTTP_URL: 'http://localhost:5005/graphql',
  REACT_APP_API_WEBSOCKET_URL: 'ws://localhost:5005/graphql',
  REACT_APP_IMAGE_BASE_URL: 'https://res.cloudinary.com/dw0ryaywl/image/upload/',
  REACT_APP_BASE_URL: 'http://localhost:3000',
}

const stage = {
  REACT_APP_BRAND: 'SAENKELT',
  REACT_APP_API_HTTP_URL: 'https://saenkelt-server-stage.osc-fr1.scalingo.io/graphql',
  REACT_APP_API_WEBSOCKET_URL: 'ws://saenkelt-server-stage.osc-fr1.scalingo.io/graphql',
  REACT_APP_IMAGE_BASE_URL: 'https://res.cloudinary.com/dw0ryaywl/image/upload/',
  REACT_APP_BASE_URL: 'http://localhost:3000',
}

const prod = {
  REACT_APP_BRAND: 'SAENKELT',
  REACT_APP_API_HTTP_URL: 'https://saenkelt-server-production.osc-fr1.scalingo.io/graphql',
  REACT_APP_API_WEBSOCKET_URL: 'wss://saenkelt-server-production.osc-fr1.scalingo.io/graphql',
  REACT_APP_IMAGE_BASE_URL: 'https://res.cloudinary.com/htgruo9xy/image/upload/',
  REACT_APP_BASE_URL: 'https://saenkelt-portal-stage.herokuapp.com',
}

/** SWITCH BETWEEN STAGE OR PRODUCTION */
const config = local;

const {
  REACT_APP_BRAND = 'SAENKELT',

  // Settings for local development
  REACT_APP_API_HTTP_URL = config.REACT_APP_API_HTTP_URL,
  REACT_APP_API_WEBSOCKET_URL = config.REACT_APP_API_WEBSOCKET_URL,

  REACT_APP_BASE_URL = config.REACT_APP_BASE_URL,
  REACT_APP_IMAGE_BASE_URL = config.REACT_APP_IMAGE_BASE_URL,
  REACT_APP_TUTORIAL_VERSION = 1,

  REACT_APP_META_TITLE = 'Så enkelt',
  REACT_APP_META_IMAGE_URL = 'https://saenkelt.se/wp-content/uploads/2022/11/sa-enkelt.png',

} = process.env;

export {
  REACT_APP_IMAGE_BASE_URL,
  REACT_APP_API_HTTP_URL,
  REACT_APP_API_WEBSOCKET_URL,
  REACT_APP_BASE_URL,
  REACT_APP_BRAND,
  REACT_APP_TUTORIAL_VERSION,
  REACT_APP_META_IMAGE_URL,
  REACT_APP_META_TITLE,
};
