import React, { useState } from 'react';

const ZoneContext = React.createContext();

export const ZoneProvider = (props) => {
  const [zone, setZone] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [adminableBooklets, setAdminableBooklets] = useState([]);
  const [filteredBooklets, setFilteredBooklets] = useState([]);
  const [hiddenBooklets, setHiddenBooklets] = useState([]);
  const [showAllBooklets, setShowAllBooklets] = useState(false);
  
  // Companies filter
  const [companiesFilter, setCompaniesFilter] = useState({
    selectedBookletId: '',
    hiddenCompanies: []
  });

  // Selected companies in booklet
  const [selectedCompaniesInBooklet, setSelectedCompaniesInBooklet] = useState([]);

  const context = {
    zone,
    companies,
    adminableBooklets,
    filteredBooklets,
    companiesFilter,
    showAllBooklets,
    hiddenBooklets,
    selectedCompaniesInBooklet,
    setSelectedCompaniesInBooklet,
    setZone,
    setCompanies,
    setAdminableBooklets,
    setCompaniesFilter,
    setShowAllBooklets,
    setFilteredBooklets,
    setHiddenBooklets,
  };

  return (
    <ZoneContext.Provider value={context}>
      {props.children}
    </ZoneContext.Provider>
  );
};

export const useZoneContext = () => React.useContext(ZoneContext);
