import React from 'react'

export const BookletSelectedCompanyItem = ({ company, booklet }) => {
  return (
    <div className='company-item'>
      <small>{company.name} - {booklet.title}</small>
    </div>
  )
}

