import React from 'react'
import { useZoneContext } from '../../context/ZoneContext';
import { useCompaniesTableContext } from '../../context/CompaniesTableContext';
import { FiLoader } from 'react-icons/fi';

const BookletSelectAllCompanies = ({ booklet }) => {
  const { companies, selectedCompaniesInBooklet, setSelectedCompaniesInBooklet } = useZoneContext();
  const { isCouponsLoading, loadedCompaniesCoupons, totalCompaniesCount, companyCoupons } = useCompaniesTableContext();

  const isCompanyActiveInBooklet = (company) => {
    const companyCouponsList = companyCoupons[company._id] || [];
    return companyCouponsList.some(coupon => booklet.couponIds.includes(coupon._id));
  };

  const areAllCompaniesSelected = companies.every(company =>
    selectedCompaniesInBooklet.some(
      selected => selected.company._id === company._id && selected.booklet._id === booklet._id
    )
  );

  // Get active and inactive companies
  const activeCompanies = companies.filter(isCompanyActiveInBooklet);
  const inactiveCompanies = companies.filter(company => !isCompanyActiveInBooklet(company));

  // Check if ONLY active companies are selected (no inactive ones)
  const areActiveCompaniesSelected =
    activeCompanies.length > 0 && // Make sure there are active companies
    activeCompanies.every(company =>  // All active companies must be selected
      selectedCompaniesInBooklet.some(
        selected => selected.company._id === company._id && selected.booklet._id === booklet._id
      )
    ) &&
    inactiveCompanies.every(company =>  // No inactive companies should be selected
      !selectedCompaniesInBooklet.some(
        selected => selected.company._id === company._id && selected.booklet._id === booklet._id
      )
    );

  const handleToggleAll = (e) => {
    e.stopPropagation();
    if (areAllCompaniesSelected) {
      // If all companies are selected for this booklet, unselect them
      setSelectedCompaniesInBooklet(prev =>
        prev.filter(item => item.booklet._id !== booklet._id)
      );
    } else {
      // If not all companies are selected for this booklet, select all
      const newSelections = companies.map(company => ({
        company,
        booklet
      }));

      setSelectedCompaniesInBooklet(prev => {
        const filtered = prev.filter(item => item.booklet._id !== booklet._id);
        return [...filtered, ...newSelections];
      });
    }
  };

  const handleToggleActiveCompanies = (e) => {
    e.stopPropagation();
    if (areActiveCompaniesSelected) {
      // If active companies are selected, unselect them
      setSelectedCompaniesInBooklet(prev =>
        prev.filter(item => item.booklet._id !== booklet._id)
      );
    } else {
      // Select only companies that have coupons in this booklet
      const newSelections = activeCompanies.map(company => ({
        company,
        booklet
      }));

      setSelectedCompaniesInBooklet(prev => {
        const filtered = prev.filter(item => item.booklet._id !== booklet._id);
        return [...filtered, ...newSelections];
      });
    }
  };

  return (
    <div className="select-all-checkbox company-checkbox-container">
      <div className="button-group">
        <button
          onClick={handleToggleAll}
          className={`select-button c-pointer ${areAllCompaniesSelected ? 'active' : ''}`}
        >
          Välj alla
        </button>
        <button
          onClick={handleToggleActiveCompanies}
          className={`select-button ${areActiveCompaniesSelected ? 'active' : ''} ${!isCouponsLoading && activeCompanies.length > 0 ? 'c-pointer' : ''} ${!isCouponsLoading && activeCompanies.length === 0 ? 'inactive' : ''}`}
          disabled={isCouponsLoading || activeCompanies.length === 0}
        >
          {!isCouponsLoading ? 'Välj aktiva' : (
            <>
              <FiLoader className='loading-spinner' size={12} color='#000' />
              {` ${loadedCompaniesCoupons}/${totalCompaniesCount}`}
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default BookletSelectAllCompanies;
