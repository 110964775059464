import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useZoneContext } from '../../context/ZoneContext';
import { ImEyeBlocked, ImEye } from 'react-icons/im';
import BookletSelectAllCompanies from './BookletSelectAllCompanies';

const CompaniesTableBooklet = ({ booklet }) => {
  const { companiesFilter, setCompaniesFilter, selectedCompaniesInBooklet, setFilteredBooklets } = useZoneContext();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const bookletRefElement = React.useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10;
      setIsScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Utility function to check if today's date is within the date range
  const isDateActive = (startDate, endDate) => {
    const today = new Date();
    return today >= new Date(startDate) && today <= new Date(endDate);
  };

  // Function to get the class name based on the date's active status
  const getDateClassName = (isActive) => isActive ? 'is-active' : '';

  const handleSelectedBooklet = () => {
    if (selectedCompaniesInBooklet.length > 0) return;
    
    setCompaniesFilter(prev => ({
      ...prev,
      selectedBookletId: prev.selectedBookletId === booklet._id ? '' : booklet._id
    }));
  };

  const handleHideBooklet = (event) => {
    event.stopPropagation();
    if (selectedCompaniesInBooklet.length > 0) return;
    
    setFilteredBooklets(prev => 
      prev.filter(b => b._id !== booklet._id)
    );
  };

  const handleSoloBooklet = (event) => {
    event.stopPropagation();
    if (selectedCompaniesInBooklet.length > 0) return;
    
    setFilteredBooklets(prev => 
      prev.filter(b => b._id === booklet._id)
    );
  };

  return (
    <div className={`booklet-column-container ${isHovered ? 'hovered' : ''}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <motion.div
        ref={bookletRefElement}
        className={`booklet-heading-container ${companiesFilter.selectedBookletId === booklet._id ? 'active' : ''}`}
        onClick={handleSelectedBooklet}
        initial={false}
        animate={isScrolled ? { top: 0 } : { top: 176 }}
        transition={{
          duration: 0.4,
          ease: 'easeOut'
        }}
        style={{
          zIndex: selectedCompaniesInBooklet.length > 0 ? 200 : 1,
          cursor: selectedCompaniesInBooklet.length > 0 ? 'default' : 'pointer',
        }}
      >
        <div className="flex">
          <div>
            <span className='booklet-heading'>{booklet?.couponIds.length + ' - '}{booklet.title} ({booklet.price}:-)</span>
            <div className='booklet-dates'>
              <div>
                <span className={getDateClassName(isDateActive(booklet.buyableFrom, booklet.buyableTo))}>
                  <b>Säljs</b> - {new Date(booklet?.buyableFrom).toLocaleDateString()} - {new Date(booklet?.buyableTo).toLocaleDateString()}
                </span>
              </div>
              <div>
                <span className={getDateClassName(isDateActive(booklet.validFrom, booklet.validTo))}>
                  <b>Giltigt</b> - {new Date(booklet?.validFrom).toLocaleDateString()} - {new Date(booklet?.validTo).toLocaleDateString()}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="booklet-filter-buttons">
          <button className='btn-hide-booklet' onClick={handleHideBooklet} disabled={selectedCompaniesInBooklet.length > 0}>
            <ImEyeBlocked size={14} />
          </button>
          <button className='btn-solo-booklet' onClick={handleSoloBooklet} disabled={selectedCompaniesInBooklet.length > 0}>
            <span className='solo-badge'>1</span>
            <ImEye size={14} />
          </button>
        </div>
        <BookletSelectAllCompanies booklet={booklet} />
      </motion.div>
      {companiesFilter.selectedBookletId === booklet._id && (
        <div className='booklet-column-background' style={{ left: bookletRefElement.current?.getBoundingClientRect().x }} />
      )}
    </div>
  );
};

export default CompaniesTableBooklet;
