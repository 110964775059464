import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import OrderBatchDownloadPDF from './OrderBatchDownloadPDF';
import { IoIosSend } from 'react-icons/io';
import { Button } from '../../common/Form';
import BookletOrderItem from './BookletOrderItem';
import { sendOrderBatchPdfLink } from '../../queries/booklet';
import { REACT_APP_BASE_URL } from '../../env';

const BookletDownloadPDFModal = ({ bookletId, selectedBatch }) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [sendOrderBatchPdfLinkMutation, { loading }] = useMutation(sendOrderBatchPdfLink);

  const sendEmail = async (e) => {
    e.stopPropagation();
    e.preventDefault();

    // Prevent multiple clicks
    if (loading) return;

    // Reset message
    setMessage(null);

    try {
      await sendOrderBatchPdfLinkMutation({
        variables: {
          email,
          bookletId,
          batchLabel: selectedBatch.batchLabel,
          portalUrlForEmails: `${REACT_APP_BASE_URL}/`
        },
      });
      setMessage('Ett mail med länk till PDF har skickats till ' + email);
    } catch (error) {
      console.error('Error sending email:', error);
      setMessage('Ett fel inträffade vid skickandet av e-post.');
    } finally {
      setEmail('');
    }
  };

  const handleDownloadCSV = (e) => {
    e.preventDefault();

    const codes = selectedBatch.orders.map(order => order.code);
    const csvContent = codes.map(code => [code]).map(e => e.join(',')).join('\n');

    const fileName = (selectedBatch?.batchLabel ? selectedBatch.batchLabel : 'Alla ordrar').replace(/ /g, '-');

    // Trigger download of the CSV file
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `${fileName}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      {/* PDF options */}
      <div className="modal-top mb-lg">
        <h4 className='block mb-lg max-w'>
          {selectedBatch?.batchLabel
            ? selectedBatch.batchLabel
            : 'Alla ordrar'
          }
        </h4>
        <OrderBatchDownloadPDF
          bookletId={bookletId}
          batchLabel={selectedBatch.batchLabel}
        />
        <b className='block'>Skicka mail med länk till PDF</b>
        <form onSubmit={sendEmail} className="flex flex-col max-w relative p-0 mb">
          <label htmlFor="email">E-postadress</label>
          <input type="email" style={{ paddingRight: 40 }} id="email" value={email} onChange={e => setEmail(e.target.value)} required />
          <Button type="submit" className="primary send-email-btn" loading={loading}>
            <IoIosSend size={20} />
          </Button>
        </form>
        {message && (
          <small className="message flex flex-col max-w">
            {message}
          </small>
        )}
      </div>

      {/* List of codes */}
      <div className="order-list">
        {selectedBatch.orders.length > 0 && (<>
          <div className="order-list-top flex items-center justify-between">
            {selectedBatch?.batchLabel
              ? <b className='text-left block'>Värdebevis ({selectedBatch.orders.length})</b>
              : <b className='text-left block'>Ordrar ({selectedBatch.orders.length})</b>
            }
            <button className='btn-export btn primary xs' onClick={handleDownloadCSV}>
              Exportera koder
            </button>
          </div>
          <ul>
            {selectedBatch.orders.map((order, index) => (
              <BookletOrderItem
                key={order._id}
                order={order}
                index={index}
              />
            ))}
          </ul>
        </>)}
      </div>
    </div>
  );
};

export default BookletDownloadPDFModal;
