import React, { useEffect, useRef, useState } from 'react'
import { REACT_APP_IMAGE_BASE_URL } from '../../env';
import { FiEdit } from 'react-icons/fi';
import { validateSvg } from '../../common';
import CouponsTable from './CouponsTable';
import { debounce } from 'lodash';
import { useZoneContext } from '../../context/ZoneContext';

function CompanyTableItem({ zoneId, company, categoriesQuery, setEditing, adminEmails, checked, companyIndex }) {
  const { companiesFilter } = useZoneContext();

  const contactDetails = [company?.contactPerson, company?.contactMobile, company?.adminEmails.join(', ')].filter(Boolean);
  const category = categoriesQuery?.categories?.find((category) => category._id === company.companyCategoryId);

  const [isCompanyHovered, setIsCompanyHovered] = useState(false);

  const [isInView, setIsInView] = useState(false);

  const [companyHeight, setCompanyHeight] = useState(0);

  const companyRef = useRef(null);

  useEffect(() => {
    const debouncedHandleScroll = debounce(([entry]) => {
      setIsInView(entry.isIntersecting);
    }, 500); // 500ms debounce (to make sure the user stopped scrolling)

    const observer = new IntersectionObserver(debouncedHandleScroll);

    if (companyRef.current) {
      observer.observe(companyRef.current);
    }

    return () => {
      if (companyRef.current) {
        observer.unobserve(companyRef.current);
      }
    };
  }, [company]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(debounce(entries => {
      for (let entry of entries) {
        if (entry.target === companyRef.current) {
          setCompanyHeight(entry.target.clientHeight);
        }
      }
    }, 100));

    if (companyRef.current) {
      resizeObserver.observe(companyRef.current);
    }

    return () => {
      if (companyRef.current) {
        resizeObserver.unobserve(companyRef.current);
      }
    };
  }, []);

  const handleMouseEnter = () => setIsCompanyHovered(true);
  const handleMouseLeave = () => setIsCompanyHovered(false);

  return (
    <>
      <tr
        ref={companyRef}
        className={`company company-row ${isCompanyHovered ? 'hovered' : ''}`}
        style={{
          opacity: companiesFilter.hiddenCompanies.includes(company._id) ? '0.25' : '1',
          position: 'relative'
        }}
      >
        <td
          className="company-info company-img"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {company.logoURL ? <img alt="Företagslogotyp" src={`${REACT_APP_IMAGE_BASE_URL}${company.logoURL}`} style={{ maxWidth: '100px', maxHeight: '60px' }} /> : ''}
        </td>
        <td
          className="company-info company-name"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <strong>{company.name}</strong>
          {contactDetails.length > 0 && <div>{contactDetails.join(', ')}</div>}
          {company?.notes && <div>{company.notes}</div>}
        </td>
        <td
          className="company-info company-category"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div>
            <div className='company-category-icon' dangerouslySetInnerHTML={{ __html: validateSvg(category?.iconPath) ? category.iconPath : '' }} />
            {category?.name}
          </div>
        </td>
        <td onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <span onClick={() => setEditing(company._id)}><FiEdit size={20} /></span>
        </td>
      </tr>
      <CouponsTable
        key={`coupons-for-${company._id}`}
        company={company}
        adminEmails={adminEmails}
        checked={checked}
        zoneId={zoneId}
        isCompanyInView={isInView}
        companyHeight={companyHeight}
        companyIndex={companyIndex}
      />
    </>
  )
}

export default CompanyTableItem
