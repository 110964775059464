import React, { createContext, useContext, useState } from 'react';

const CompaniesTableContext = createContext();

export const useCompaniesTableContext = () => {
  return useContext(CompaniesTableContext);
};

// This handles the loading state of coupons for each company
// It's used to load coupons for companies in batches to avoid overwhelming the server
export const CompaniesTableProvider = ({ children }) => {
  const [totalCompaniesCount, setTotalCompaniesCount] = useState(0);
  const [loadedCompaniesCoupons, setLoadedCompaniesCoupons] = useState(0);
  const [currentBatchStart, setCurrentBatchStart] = useState(0);
  const [loadedCompanyIds, setLoadedCompanyIds] = useState(new Set());
  const [companyCoupons, setCompanyCoupons] = useState({});

  const BATCH_SIZE = 10; // Number of companies to load simultaneously

  const markCompanyAsLoaded = (companyId, coupons) => {
    if (!loadedCompanyIds.has(companyId)) {
      setLoadedCompanyIds(prev => new Set([...prev, companyId]));
      setLoadedCompaniesCoupons(prev => prev + 1);
      setCompanyCoupons(prev => ({
        ...prev,
        [companyId]: coupons
      }));

      if (loadedCompanyIds.size % BATCH_SIZE === 0) {
        const nextBatchStart = Math.min(currentBatchStart + BATCH_SIZE, totalCompaniesCount);
        setCurrentBatchStart(nextBatchStart);
      }
    }
  };

  return (
    <CompaniesTableContext.Provider
      value={{
        batchSize: BATCH_SIZE,
        isCouponsLoading: totalCompaniesCount > loadedCompaniesCoupons,
        loadedCompaniesCoupons,
        totalCompaniesCount,
        currentBatchStart,
        setTotalCompaniesCount,
        setLoadedCompaniesCoupons,
        markCompanyAsLoaded,
        loadedCompanyIds,
        companyCoupons
      }}
    >
      {children}
    </CompaniesTableContext.Provider>
  );
};
