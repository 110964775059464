import React, { useEffect, useState } from 'react';
import { GrClose } from 'react-icons/gr';
import { FaCheck } from 'react-icons/fa';
import { useZoneContext } from '../../context/ZoneContext';
import { BookletSelectedCompanyItem } from './BookletSelectedCompanyItem';
import { useMutation } from 'react-apollo';
import { sendOrderPdfLink } from '../../queries/booklet';
import { createCompletedOrdersMutation, sendEmail } from '../../queries/queries';
import { REACT_APP_BASE_URL } from '../../env';
import { Button } from '../../common/Form';
import { MdOutlineMail } from 'react-icons/md';


function BookletSelectedCompaniesPopup(props) {
  const { booklet, company, companyHeight, companyIndex, bookletIndex } = props;

  const { selectedCompaniesInBooklet, setSelectedCompaniesInBooklet } = useZoneContext();
  const [createCompletedOrders, { loading: createLoading, error: createError }] = useMutation(createCompletedOrdersMutation);
  const [sendOrderPdfLinkMutation, { loading: sendOrderPdfLinkLoading, error: sendOrderPdfLinkError }] = useMutation(sendOrderPdfLink);
  const [sendEmailMutation, { loading: sendEmailLoading, error: sendEmailError }] = useMutation(sendEmail);

  const presetTitle = 'Värdebevis till {company}';
  const presetMessage = `Här kommer ett värdebevis för häftet {booklet}.\n\n` +
    'Klicka på knappen nedan för att gå till sidan där du kan ladda ner PDF:en.';

  const [isCompanyCheckboxHovered, setIsCompanyCheckboxHovered] = useState(false);
  const [error, setError] = useState('');

  const [isMessageWithPdf, setIsMessageWithPdf] = useState(false);

  const [titleWithPdfToCompany, setTitleWithPdfToCompany] = useState(presetTitle);
  const [messageWithPdfToCompany, setMessageWithPdfToCompany] = useState(presetMessage);
  const [titleToCompany, setTitleToCompany] = useState('');
  const [messageToCompany, setMessageToCompany] = useState('');

  const isFirstCellItem = companyIndex === 0 && bookletIndex === 0;

  const isCompanySelected = selectedCompaniesInBooklet?.some(
    item => item.company._id === company._id && item.booklet._id === booklet._id
  );

  const handleCloseCompanyPopup = () => {
    setSelectedCompaniesInBooklet([]);
    setError('');
  }

  const toggleSelectedCompany = (company, booklet) => {
    setSelectedCompaniesInBooklet(prev => {
      const existingIndex = prev.findIndex(
        item => item.company._id === company._id && item.booklet._id === booklet._id
      );
      if (existingIndex >= 0) {
        return prev.filter((_, index) => index !== existingIndex);
      } else {
        return [...prev, { company, booklet }];
      }
    });
  };

  useEffect(() => {
    if (createError) {
      setError('Något gick fel vid skapandet av värdebeviset');
    } else if (sendOrderPdfLinkError) {
      setError('Något gick fel när värdebeviset skulle skickas');
    } else if (sendEmailError) {
      setError('Något gick fel när meddelandet skulle skickas');
    }
  }, [createError, sendOrderPdfLinkError, sendEmailError]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      if (isMessageWithPdf) {
        // Group selected companies by their booklet
        const groupCompaniesByBooklet = selectedCompaniesInBooklet.reduce((acc, curr) => {
          if (!acc[curr.booklet._id]) {
            acc[curr.booklet._id] = {
              booklet: curr.booklet,
              companies: []
            };
          }
          acc[curr.booklet._id].companies.push(curr.company);
          return acc;
        }, {});

        // Process each booklet group separately
        for (const [bookletId, group] of Object.entries(groupCompaniesByBooklet)) {
          // Create completed orders for all companies in this booklet
          const { data } = await createCompletedOrders({
            variables: {
              bookletId,
              amount: group.companies.length
            }
          });

          // Send email to each company with their specific order details
          for (const [index, company] of group.companies.entries()) {
            await sendOrderPdfLinkMutation({
              variables: {
                bookletId,
                orderCode: data.createCompletedOrders[index].code,
                orderId: data.createCompletedOrders[index]._id,
                emails: company.adminEmails,
                portalUrlForEmails: `${REACT_APP_BASE_URL}/`,
                title: titleWithPdfToCompany.replace(/{booklet}/g, group.booklet.title).replace(/{company}/g, company.name),
                message: messageWithPdfToCompany.replace(/\n/g, '<br />').replace(/{booklet}/g, group.booklet.title).replace(/{company}/g, company.name)
              }
            });
          }
        }

        handleCloseCompanyPopup();
        setMessageWithPdfToCompany(presetMessage);
      } else {
        // Simple email sending logic
        for (const item of selectedCompaniesInBooklet) {
          await sendEmailMutation({
            variables: {
              emails: item.company.adminEmails,
              title: titleToCompany.replace(/{booklet}/g, item.booklet.title).replace(/{company}/g, item.company.name),
              message: messageToCompany.replace(/\n/g, '<br />').replace(/{booklet}/g, item.booklet.title).replace(/{company}/g, item.company.name)
            }
          });
        }
        handleCloseCompanyPopup();
      }
    } catch (error) {
      console.log(error);
      setError('Något gick fel när meddelandet skulle skickas');
    }
  };

  const sortedCompanies = [...selectedCompaniesInBooklet].sort(
    (a, b) => a.company.name.localeCompare(b.company.name)
  );

  return (
    <>
      {/* {isOpen && <div className='pop-up-overlay'></div>} */}
      {isFirstCellItem && selectedCompaniesInBooklet.length > 0 && (
        <div className='pop-up-overlay'></div>
      )}

      {/* Company checkbox container */}
      <div className={`company-checkbox-container pop-up-btn flex items-center justify-center ${isCompanySelected ? 'selected' : ''} `}
        style={{ top: -companyHeight - 4, zIndex: selectedCompaniesInBooklet.length > 0 ? 100 : 0 }}
        onMouseEnter={() => setIsCompanyCheckboxHovered(true)}
        onMouseLeave={() => setIsCompanyCheckboxHovered(false)}
      >
        {(isCompanyCheckboxHovered || isCompanySelected) ? (
          <label className="company-checkbox">
            <input
              type="checkbox"
              onChange={() => toggleSelectedCompany(company, booklet)}
              checked={isCompanySelected}
            />
            <span className={isCompanySelected ? 'checked' : ''}>
              {isCompanySelected && (
                <span className="check-icon">
                  <FaCheck size={14} />
                </span>
              )}
            </span>
          </label>
        ) : (
          <MdOutlineMail size={24} opacity={0.5} />
        )}
      </div>

      {/* Company popup content */}
      {isFirstCellItem && selectedCompaniesInBooklet.length > 0 &&
        <form className='pop-up company-popup' onSubmit={handleSubmit} style={{ opacity: 1 }}>
          <div className="section">
            <h3>Meddelande till företag</h3>
            <div className='company-list'>
              {sortedCompanies.map((item, index) => (
                <BookletSelectedCompanyItem
                  key={index}
                  company={item.company}
                  booklet={item.booklet}
                />
              ))}
            </div>
          </div>

          <div className="section">
            {isMessageWithPdf ? (
              <>
                <input
                  type="text"
                  value={titleWithPdfToCompany}
                  onChange={(e) => setTitleWithPdfToCompany(e.target.value)}
                  placeholder="Ange titel"
                  required
                />
                <textarea
                  className="comment-box"
                  value={messageWithPdfToCompany}
                  onChange={(e) => setMessageWithPdfToCompany(e.target.value)}
                  required
                />
              </>
            ) : (
              <>
                <input
                  type="text"
                  value={titleToCompany}
                  onChange={(e) => setTitleToCompany(e.target.value)}
                  placeholder="Ange titel"
                  required
                />
                <textarea
                  className="comment-box"
                  value={messageToCompany}
                  onChange={(e) => setMessageToCompany(e.target.value)}
                  placeholder='Meddelande...'
                  required
                />
              </>
            )}

            <div className="include-pdf-checkbox-container company-checkbox-container">
              <label className="company-checkbox include-pdf-checkbox">
                <input
                  type="checkbox"
                  checked={isMessageWithPdf}
                  onChange={(e) => setIsMessageWithPdf(e.target.checked)}
                  className='company-checkbox'
                />
                <span className={isMessageWithPdf ? 'checked' : ''}>
                  {isMessageWithPdf && (
                    <span className="check-icon">
                      <FaCheck size={14} />
                    </span>
                  )}
                </span>
                <small>Skicka med värdebevis (PDF)</small>
              </label>
            </div>
          </div>

          <div className='close' onClick={() => handleCloseCompanyPopup()}><GrClose /></div>

          <Button
            type="submit"
            className='primary company-popup-button'
            loading={sendOrderPdfLinkLoading || sendEmailLoading || createLoading}
          >
            Skicka
          </Button>

          {error && (
            <div className="error">
              {error}
            </div>
          )}
        </form>
      }

    </>
  )
}

export default BookletSelectedCompaniesPopup;
